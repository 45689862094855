import React from 'react';
import Section from '../components/Section';
import Metadata from '../components/Metadata';

function narrativetherapie() {
  return (
    <>
      <Metadata title='Narrative Therapie' />
      <Section heading='Narrative Therapie'>
        <p>
          Die Auseinandersetzung mit der Diagnose Autismus als tiefgreifende
          Entwicklungsstörung erfordert in den meisten Fällen auch etwas
          Biographiearbeit. Guten Zugang hierzu eröffnen Methoden aus der{' '}
          <b>narrativen Therapie</b>. Das kreativ unterstützte, erzählende
          Entdecken des eigenen Lebensweges regt selbstwertförderliche
          Sinngebungen an, unterstützt die Entwicklung neuer Perspektiven auf
          sich selbst und andere und eröffnet eine selbstbestimmte
          Fortschreibung der eigenen Lebensgeschichte und das Entdecken
          intrinsisch motivierender Entwicklungsziele. Auch kritische
          Lebensereignisse finden hier ihren Platz und können mithilfe der
          narrativen Therapie verarbeitet werden.
        </p>
      </Section>
    </>
  );
}

export default narrativetherapie;
